import { it } from 'date-fns/locale';

/**
 * @see https://kazupon.github.io/vue-i18n/guide/messages.html#structure
 * Purtroppo non sono riuscito a importare globalmente l'interfaccia dei context message.
 * TODO: cercare di far funzionare il @type o esportare globalmente la context.
 */
export type ContextMessage = {
  named: (key: string) => string;
};
/** @type { import('vue-i18n').dist.ComposerResolveLocaleMessageTranslation } */
export default {
  dateLocale: it, // At the moment, only itIT is supported

  titlePage: {
    home: 'Dashboard',
    candidates: 'Candidates',
    matches: 'Matches',
    jobPositions: 'Job Positions',
    companies: 'Companies',
    search: 'Search',
    addUser: 'Add user'
  },
  candidates: {
    stats: {
      totalCand: 'Total candidates',
      totalMatches: 'Total matches',
      data: 'This month - ',
      matchesCand: 'Candidates with matches',
      totalOpportunities: 'Total opportunities',
      applicationsCand: 'Candidates with applications',
      acceProposals: 'Acce. proposals',
      offers: 'Offers'
    },
    table: {
      candidate: 'Candidate',
      submitted: 'Subscription date',
      openToWork: 'Open to work',
      openSelection: 'Open selections',
      devTalentPartner: 'Dev Talent Partner',
      ral: 'RAL',
      jobRole: 'Job role',
      skills: 'Skills',
      toBeAssigned: 'To be assigned',
      messageShortlist: 'Mess/Shortlist',
      notes: 'Notes',
      desiredRoles: 'Desired roles',
      reasonWhy: 'Reason why',
      companyPreferences: 'Company preferences'
    },
    details: {
      tabs: {
        details: 'Candidate details',
        statistics: 'Statistics',
        matches: 'Matches shortlist',
        applications: 'Applications',
        openSelections: 'Open selections',
        history: 'History',
        log: 'Events log'
      },
      stats: {
        readyToWork: 'Ready to work',
        desiredSalary: 'Desired salary',
        workPref: 'Work preference',
        seniority: 'Seniority'
      },
      resume: 'Resume',
      linkedin: 'Linkedin',
      github: 'Github',
      website: 'Website',
      companyPreferencesQuotes: {
        choice_factors:
          'What are the most important factors you consider when choosing the company you work for?',
        focus:
          'What focus should the ideal company where you want to work have?',
        dimension: 'What type of company would you like to go to work at?'
      }
    },
    matches: {
      jobPosition: 'Job position',
      matchDate: 'Match date',
      company: 'Company',
      matchMaker: 'Matchmaker',
      notes: 'Notes',
      status: 'Match events progress',
      onMyTalent: 'On My Talent',
      affinity: 'Affinity',
      positonType: 'Product type',
      listed: 'Listed',
      readyToPublish: 'Ready to publish',
      matchesLoaded: 'Successfully updated',
      inShortlist: 'matches in shortlist'
    },
    applications: {
      jobAffinity: 'Job affinity',
      status: 'Status'
    },
    formTabs: {
      basicInformation: 'Basic information',
      socialLinks: 'Social links',
      knowledgeEducation: 'Knowledge and education',
      jobPreferences: 'Job preferences',
      companyPreferences: 'Company preferences',
      resume: 'Resume'
    }
  },
  companies: {
    stats: {
      activeCompanies: 'Active companies',
      italyActive: 'Italy active',
      spainActive: 'Spain active',
      activeTalentCore: 'Active Talent core',
      activeTalentPro: 'Active Talent Pro'
    },
    table: {
      name: 'Name',
      companyId: 'Company id',
      country: 'Country',
      currentlyLiveJobs: 'Currently live jobs',
      users: 'Users',
      hasBrandedPage: 'Has branded page',
      actions: 'Actions'
    },
    company: {
      tabs: {
        details: 'Company details',
        users: 'Users',
        jobPositions: 'Job positions',
        matches: 'Matches',
        applications: 'Applications',
        openSelections: 'Open selections',
        history: 'History'
      },
      stats: {
        size: 'Company size',
        headquarters: 'Headquarters',
        industry: 'Industry'
      },
      details: {
        companyInfo: 'Company info',
        techStack: 'Tech stack',
        keyValues: 'Key values',
        description: 'Description',
        vat: 'VAT number',
        sponsorSlug: 'Company slug',
        privacyPolicyUrl: 'Privacy policy URL',
        partnerType: 'Partner type',
        privacyPolicy: 'Privacy policy'
      },
      users: {
        users: 'Users',
        role: 'Role',
        mobile: 'Mobile',
        registrationDate: 'Registration date'
      },
      jobPositions: {
        jobPositions: 'Job positions',
        country: 'Country',
        talentPartner: 'Talent partner',
        candidatesSent: 'Candidates sent',
        openSelections: 'Open selections',
        positionType: 'Product type',
        created: 'Created',
        status: 'Status'
      },
      matches: {
        candidate: 'Candidate',
        position: 'Position',
        matchDate: 'Match date',
        matchmaker: 'Matchmaker',
        positionType: 'Product type',
        affinity: 'Affinity',
        note: 'Notes',
        onMyTalent: 'On My Talent'
      },
      applications: {
        candidate: 'Candidate',
        ral: 'RAL',
        seniority: 'Seniority',
        skills: 'Skills',
        devTalentPartner: 'Dev Talent Partner',
        matchmaker: 'Matchmaker',
        matchDate: 'Match date',
        status: 'Status'
      },
      openSelections: {
        candidate: 'Candidate',
        position: 'Position',
        oppSendDate: 'Opp. send date',
        matchmaker: 'Matchmaker',
        affinity: 'Affinity',
        notes: 'Notes',
        status: 'Status'
      }
    }
  },
  jobPositions: {
    stats: {
      totalJobs: 'Total jobs',
      onlineJobs: 'Online jobs',
      offlineJobs: 'Offline jobs',
      candidatesSent: 'Candidates Sent',
      openSelections: 'Open selections'
    },
    table: {
      jobPosition: 'Job position',
      company: 'Company',
      country: 'Country',
      companyPartner: 'Company partner',
      created: 'Created',
      candidatesSent: 'Candidates sent',
      status: 'Status',
      actions: 'Actions'
    },
    job: {
      tabs: {
        details: 'Job details',
        statistics: 'Statistics',
        matches: 'Matches',
        applications: 'Applications',
        openSelections: 'Open selections',
        history: 'History'
      },
      details: {
        stats: {
          workingModel: 'Working model',
          workplaces: 'Workplaces',
          offeredSalary: 'Offered salary',
          typeOfContract: 'Type of contract',
          requiredExperience: 'Required experience'
        },
        requiredQualifications: 'Required qualifications',
        jobDescription: 'Job description',
        niceToHave: 'Nice to have',
        mustHave: 'Must have'
      },
      statistics: {
        totalOpportunties: 'Total opportunities',
        openSelections: 'Open selections',
        offer: 'Offer',
        totalMatches: 'Total matches',
        archived: 'Archived',
        interviews: 'Interviews',
        hired: 'Hired'
      },
      matches: {
        candidate: 'Candidate',
        ral: 'RAL',
        seniority: 'Seniority',
        skills: 'Skills',
        devTalentPartner: 'Dev Talent Partner',
        matchmaker: 'Matchmaker',
        matchDate: 'Match date',
        status: 'Status'
      }
    }
  },
  matches: {
    tabs: {
      matches: 'Matches',
      applications: 'Applications',
      openSelections: 'Open Selections',
      history: 'History'
    },
    matches: {
      candidate: 'Candidate',
      position: 'Position',
      company: 'Company',
      matchDate: 'Match date',
      matchmaker: 'Matchmaker',
      positionType: 'Product type',
      affinity: 'Affinity',
      notes: 'Notes',
      status: 'Status',
      actions: 'Actions'
    }
  },
  actions: {
    exportJobsCsv: 'Export jobs list csv',
    importJobsCsv: 'Import jobs list csv',
    viewCandDet: 'View Candidate details',
    viewCompDet: 'View company details',
    viewCompBrandPage: 'View branded page',
    editCompany: 'Edit company',
    editCandDet: 'Edit Candidate details',
    editJobPosition: 'Edit job details',
    editJob: 'Edit job',
    editCandidate: 'Edit candidate',
    addNote: 'Add note',
    addNewMatch: 'Add new match',
    addNewJob: 'Add new position',
    addUser: 'Add user',
    addMatch: 'Add match',
    addPosition: 'Add position',
    addNewPosition: 'Add new position',
    searchCandidate: 'Search Candidate',
    searchPosition: 'Search position',
    searchUser: 'Search user',
    searchJobPosition: 'Search job position',
    searchComapny: 'Search company',
    yes: 'Yes',
    no: 'No',
    gSheet: 'Export on Gsheet',
    vieActivecampaign: 'View on ACampaign',
    edit: 'Edit',
    proposalAccepted: 'Proposal accepted',
    proposalRejected: 'Proposal rejected',
    delistMatchShortlist: 'Delist match from shortlist',
    editMatch: 'Edit match',
    editUser: 'Edit user',
    addNoteMatch: 'Add note to match',
    viewJobBoard: 'View on job board',
    failedPositionUnpublished: 'Failed for position unpublished',
    publishShortlist: 'Publish shortlist',
    archive: 'Archive',
    publishOnClient: 'Publish on Client Dashboard',
    offerRejected: 'Offer rejected',
    offerAccepted: 'Offer accepted',
    failedForJobUnpublished: 'Failed for job unpublished',
    rejectedByCompany: 'Rejected by company',
    rejectedByDev: 'Rejected by dev',
    moveSelectionToOffer: 'Move selection to offer',
    moveSelectionToInterview: 'Move selection to interview',
    restoreToShortlist: 'Restore to shortlist',
    newest: 'Newest',
    oldest: 'Oldest',
    candidateNotes: 'Candidate notes',
    matchNotes: 'Match notes',
    matchEvents: 'Match events',
    all: 'All',
    save: 'Save',
    delete: 'Yes, delete',
    cancel: 'Cancel',
    profile: 'Profile',
    logout: 'Logout',
    isTalent: 'Is Talent',
    viewJobDetails: 'View job details'
  },
  charts: {
    yearsExp: '{num}y exp.'
  },
  snackbars: {
    matchAdded: 'Match successfully added',
    matchUpdated: 'Match successfully updated',
    jobUpdated: 'Job Position successfully updated',
    jobAdded: 'Job Position successfully added',
    userAdded: 'User successfully added',
    candidateDeleted: 'Candidate successfully deleted',
    candidateSaved: 'Successfully updated',
    userEdited: 'User successfully updated',
    userDeleted: 'User successfully deleted',
    jobPositionsDeleted: 'Job Position successfully deleted',
    successMovedToHistory: 'Successfully moved to "History”',
    successMovedToApplications: 'Successfully moved to "Applications”',
    successMovedToOpenSelections: 'Successfully moved to "Open selections”',
    successRestoredShortlist: 'Successfully restored to "Matches shortlist”'
  },
  dialogs: {
    jobAffinityMandatory: {
      title: 'Job affinity mandatory!',
      message:
        'It is necessary to enter a job affinity value to move on "Open selections" section.',
      confirmLabel: 'Yes, updates job affinity'
    },
    deleteCandidate: {
      title: 'Confirm delete',
      message: 'Are you sure you want to delete this candidate?'
    },
    deleteUser: {
      title: 'Confirm delete',
      message: 'Are you sure you want to delete this user?',
      confirmLabel: 'Yes, updates job affinity'
    },
    deleteSkill: {
      title: 'Confirm delete',
      message: 'Are you sure you want to delete the skill?',
      confirmLabel: 'Yes, delete'
    },
    publishShortlist: {
      title: 'Confirm publish shortlist',
      message:
        'Are you sure you want to publish shortlist?<br>All the “Ready to publish” and “Published” matches will be included. Please remove any unwanted matches from the next shortlist.',
      confirmLabel: 'Yes, publish shortlist'
    },
    restoreToShortlist: {
      title: 'Confirm restore to shortlist',
      message: (ctx: ContextMessage) =>
        `Do you want to confirm the restore of the “${ctx.named(
          'jobName'
        )}" job in the matches shortlist?`,
      confirmLabel: 'Yes, confirm'
    },
    delistMatchFromShortlist: {
      title: 'Confirm delist match from shortlist',
      message:
        'Do you want to confirm delist match from the shortlist?<br>Once you confirm the match will be moved to “History” in “Delisted match" status.',
      confirmLabel: 'Yes, confirm'
    },
    delistMatchFromShortlistAndUpdateShortlist: {
      title: 'Confirm delist match from shortlist',
      message:
        'Are you sure you want to publish shortlist?<br>All the “Ready to publish” and “Published” matches will be included. Please remove any unwanted matches from the next shortlist.<br><br>The match is currently published on MyTalent. Click “Update Shortlist” to refresh it.',
      confirmLabel: 'Yes, confirm'
    },
    failedForPositionUnpublished: {
      title: 'Confirm failed for position unpublished',
      message:
        'Do you want to confirm failed for position unpublished?<br>Once you confirm the match will be moved to “History" in “Failed match” status.',
      confirmLabel: 'Yes, confirm'
    },
    failedForJobUnpublished: {
      title: 'Confirm failed for job unpublished',
      message:
        'Do you want to confirm the failed for job unpublished from job selection?<br>Once you confirm the match will be moved to “History” in “Failed selection” status.',
      confirmLabel: 'Yes, confirm'
    },
    proposalAccepted: {
      title: 'Confirm proposal accepted',
      message:
        'Do you want to confirm proposal accepted?<br>Once you confirm the match will be moved to “Applications” in “Opportunity” status.',
      confirmLabel: 'Yes, confirm'
    },
    proposalRejected: {
      title: 'Confirm proposal rejected',
      message:
        'Do you want to confirm the proposal rejected from the shortlist?<br>Once you confirm the match will be moved to “History” in “Failed match” status.',
      confirmLabel: 'Yes, confirm'
    },
    offerAccepted: {
      title: 'Confirm offer accepted',
      message:
        'Do you want to confirm the offer accepted from job selection?<br>Once you confirm the match will be moved to “History” in  “Hired” status.',
      confirmLabel: 'Yes, confirm'
    },
    offerRejected: {
      title: 'Confirm offer rejected',
      message:
        'Do you want to confirm the offer rejected from job selection?<br>Once you confirm the match will be moved to “History” in “Failed selection” status.',
      confirmLabel: 'Yes, confirm'
    },
    rejectedByDev: {
      title: 'Confirm rejected by dev',
      message:
        'Do you want to confirm the rejected by dev from job selection?<br>Once you confirm the match will be moved to “History” in “Failed selection” status.',
      confirmLabel: 'Yes, confirm'
    },
    rejectedByCompany: {
      title: 'Confirm rejected by company',
      message:
        'Do you want to confirm the rejected by company from job selection?<br>Once you confirm the match will be moved to “History” in “Failed selection” status.',
      confirmLabel: 'Yes, confirm'
    },
    publishOnOpenSelections: {
      title: 'Confirm publish on open selections',
      message: (ctx: ContextMessage) =>
        `Are you sure?<br>The match between “${ctx.named(
          'candidateFullname'
        )}" and “${ctx.named(
          'jobName'
        )}" will be moved to Open Selections section and will be published on the company dashboard for “${ctx.named(
          'companyName'
        )}".<br>Assigned affinity: ${ctx.named('affinity')}%`,
      confirmLabel: 'Yes, published on open selections'
    },
    moveSelectionToOffer: {
      title: 'Confirm move selection to offer',
      message: (ctx: ContextMessage) =>
        `Do you want to confirm move selection to offer for “${ctx.named(
          'jobName'
        )}” job selection?`,
      confirmLabel: 'Yes, confirm'
    },
    moveSelectionToInterview: {
      title: 'Confirm move selection to interview',
      message: (ctx: ContextMessage) =>
        `Do you want to confirm move selection to interview for “${ctx.named(
          'jobName'
        )}” job selection?`,
      confirmLabel: 'Yes, confirm'
    },
    archiveJob: {
      title: 'Confirm archive job',
      message:
        'Do you confirm the archiving of the job position?<br>Once confirmed, the match will be hidden.',
      confirmLabel: 'Yes, confirm'
    },
    leavingThePage: {
      title: 'Confirm leaving the page',
      message:
        "Are you sure that you want to leave the current page? The changes that you made won't be saved.",
      confirmLabel: 'Yes, confirm'
    }
  },
  tooltip: {
    contactSended: 'First contact sent',
    matchesShortlistListed: 'Matches shortlist listed'
  },
  validation: {
    required: 'Required'
  }
};
