import * as Sentry from '@sentry/browser';
import * as pkg from '~/package.json';

export default defineNuxtPlugin(() => {
  Sentry.init({
    enabled:
      import.meta.env.VITE_APP_ENV === 'stage' ||
      import.meta.env.VITE_SENTRY_ENABLED === 'true',
    autoSessionTracking: false,
    debug: import.meta.env.VITE_APP_ENV === 'local',
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: `v@${pkg.version}`,
    environment: import.meta.env.VITE_APP_ENV,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    sampleRate: 1,
    tracesSampleRate: 1,
    tracePropagationTargets: [],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event) {
      if (event.exception && import.meta.env.VITE_APP_ENV === 'stage') {
        Sentry.showReportDialog({ eventId: event.event_id });
      }

      return event;
    }
  });

  return {
    provide: {
      sentry: Sentry,
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException
    }
  };
});
