import { createI18n } from 'vue-i18n';
import enUS from '~/lang/en';

type MessageSchema = typeof enUS;

export default defineNuxtPlugin((nuxt) => {
  const i18n = createI18n<[MessageSchema], 'en-US'>({
    legacy: false,
    globalInjection: true,
    locale: 'en-US',
    messages: {
      'en-US': enUS
    }
  });

  nuxt.vueApp.use(i18n);
});
