import revive_payload_client_4sVQNw7RlN from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import path_client_2CrkUkjKpA from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/plugins/path.client.ts";
import router_jmwsqit4Rs from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_S3RZpeDUYv from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/node_modules/nuxt-viewport/dist/runtime/plugin.mjs";
import composition_sLxaNGmlSL from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import axios_instance_XbMgge6nxM from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/plugins/axios-instance.ts";
import cm_auth_service_instance_6v6lpdAwt1 from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/plugins/cm-auth-service-instance.ts";
import cm_design_system_wqRKTkkxIy from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/plugins/cm-design-system.ts";
import i18n_VfGcjrvSkj from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/plugins/i18n.ts";
import key_values_utils_m2H5HckeJb from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/plugins/key-values-utils.ts";
import sentry_client_shVUlIjFLk from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/plugins/sentry.client.ts";
import skills_utils_RQwIBSj9DN from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/plugins/skills-utils.ts";
import vue_apexcharts_ST6zboj3Pi from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/plugins/vue-apexcharts.ts";
import vue_dragscroll_gxCFtVqzJM from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/plugins/vue-dragscroll.ts";
import vue_json_viewer_koKP6p2V4D from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/plugins/vue-json-viewer.ts";
import vue_markdown_1aAhXhgz9b from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/plugins/vue-markdown.ts";
import vue_mavon_editor_qWjkw70hap from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/plugins/vue-mavon-editor.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  path_client_2CrkUkjKpA,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_S3RZpeDUYv,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  axios_instance_XbMgge6nxM,
  cm_auth_service_instance_6v6lpdAwt1,
  cm_design_system_wqRKTkkxIy,
  i18n_VfGcjrvSkj,
  key_values_utils_m2H5HckeJb,
  sentry_client_shVUlIjFLk,
  skills_utils_RQwIBSj9DN,
  vue_apexcharts_ST6zboj3Pi,
  vue_dragscroll_gxCFtVqzJM,
  vue_json_viewer_koKP6p2V4D,
  vue_markdown_1aAhXhgz9b,
  vue_mavon_editor_qWjkw70hap
]