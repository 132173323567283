import { default as applicationsSqbafz0JVUMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/[idSlug]/applications.vue?macro=true";
import { default as detailsBm3jShFnmgMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/[idSlug]/details.vue?macro=true";
import { default as historyDW4UD7fVFqMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/[idSlug]/history.vue?macro=true";
import { default as logD0r45VTqvNMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/[idSlug]/log.vue?macro=true";
import { default as matchesL1j5R5I5l0Meta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/[idSlug]/matches.vue?macro=true";
import { default as openSelectionsxbTGNWrwYDMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/[idSlug]/openSelections.vue?macro=true";
import { default as statisticsQJAVMubjnwMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/[idSlug]/statistics.vue?macro=true";
import { default as _91idSlug_93TT5wEUhtIPMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/[idSlug].vue?macro=true";
import { default as add_45new_45matchmSncNoDuuPMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/add-new-match.vue?macro=true";
import { default as edit_45candidateVh7cSupZYFMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/edit-candidate.vue?macro=true";
import { default as edit_45matchyanzUzJ8tzMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/edit-match.vue?macro=true";
import { default as index8qoBs9RHF9Meta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/index.vue?macro=true";
import { default as applications3wpQj5Kw3dMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/companies/[companyId]/applications.vue?macro=true";
import { default as detailsqWURrbsmbqMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/companies/[companyId]/details.vue?macro=true";
import { default as historyObAKukkssRMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/companies/[companyId]/history.vue?macro=true";
import { default as jobPositionsF73rqlxTLZMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/companies/[companyId]/jobPositions.vue?macro=true";
import { default as matchesKl3Vwv0wUtMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/companies/[companyId]/matches.vue?macro=true";
import { default as openSelectionspzAU4CgS2AMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/companies/[companyId]/openSelections.vue?macro=true";
import { default as usersFJCP46mIfxMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/companies/[companyId]/users.vue?macro=true";
import { default as _91companyId_93WB74GTE9NSMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/companies/[companyId].vue?macro=true";
import { default as add_45new_45userFJMrVcWImtMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/companies/add-new-user.vue?macro=true";
import { default as edit_45userHwlFxMsugDMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/companies/edit-user.vue?macro=true";
import { default as indexXDuUlZTeyJMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/companies/index.vue?macro=true";
import { default as index5i8gmPKmlIMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/index.vue?macro=true";
import { default as applicationsqPGJYXZEq1Meta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/job-positions/[jobId]/applications.vue?macro=true";
import { default as detailsgnxIF9aZmCMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/job-positions/[jobId]/details.vue?macro=true";
import { default as historybcIxhNeiu0Meta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/job-positions/[jobId]/history.vue?macro=true";
import { default as matchesxYmEEGLlsaMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/job-positions/[jobId]/matches.vue?macro=true";
import { default as openSelectionsq1gzsBmEnTMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/job-positions/[jobId]/openSelections.vue?macro=true";
import { default as statisticsghne00eiMoMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/job-positions/[jobId]/statistics.vue?macro=true";
import { default as _91jobId_939kNSMWaTmCMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/job-positions/[jobId].vue?macro=true";
import { default as add_45new_45jobJRehqABxkRMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/job-positions/add-new-job.vue?macro=true";
import { default as edit_45jobpoFKpWNxrgMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/job-positions/edit-job.vue?macro=true";
import { default as index_322TURZEJnGVeMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/job-positions/index 2.vue?macro=true";
import { default as indexmlpnnAIyeXMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/job-positions/index.vue?macro=true";
import { default as add_45new_45matchWtHNqicENXMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/matches/add-new-match.vue?macro=true";
import { default as applicationsxhqGp7jgPNMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/matches/applications.vue?macro=true";
import { default as historyWKFXdGgRz1Meta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/matches/history.vue?macro=true";
import { default as index5pIWalzVbqMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/matches/index.vue?macro=true";
import { default as matchesytNMNP8VRhMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/matches/matches.vue?macro=true";
import { default as openSelectionsL7H8tKl89VMeta } from "/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/matches/openSelections.vue?macro=true";
export default [
  {
    name: _91idSlug_93TT5wEUhtIPMeta?.name ?? "candidates-idSlug",
    path: _91idSlug_93TT5wEUhtIPMeta?.path ?? "/candidates/:idSlug()",
    children: [
  {
    name: applicationsSqbafz0JVUMeta?.name ?? "candidates-idSlug-applications",
    path: applicationsSqbafz0JVUMeta?.path ?? "applications",
    meta: applicationsSqbafz0JVUMeta || {},
    alias: applicationsSqbafz0JVUMeta?.alias || [],
    redirect: applicationsSqbafz0JVUMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/[idSlug]/applications.vue").then(m => m.default || m)
  },
  {
    name: detailsBm3jShFnmgMeta?.name ?? "candidates-idSlug-details",
    path: detailsBm3jShFnmgMeta?.path ?? "details",
    meta: detailsBm3jShFnmgMeta || {},
    alias: detailsBm3jShFnmgMeta?.alias || [],
    redirect: detailsBm3jShFnmgMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/[idSlug]/details.vue").then(m => m.default || m)
  },
  {
    name: historyDW4UD7fVFqMeta?.name ?? "candidates-idSlug-history",
    path: historyDW4UD7fVFqMeta?.path ?? "history",
    meta: historyDW4UD7fVFqMeta || {},
    alias: historyDW4UD7fVFqMeta?.alias || [],
    redirect: historyDW4UD7fVFqMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/[idSlug]/history.vue").then(m => m.default || m)
  },
  {
    name: logD0r45VTqvNMeta?.name ?? "candidates-idSlug-log",
    path: logD0r45VTqvNMeta?.path ?? "log",
    meta: logD0r45VTqvNMeta || {},
    alias: logD0r45VTqvNMeta?.alias || [],
    redirect: logD0r45VTqvNMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/[idSlug]/log.vue").then(m => m.default || m)
  },
  {
    name: matchesL1j5R5I5l0Meta?.name ?? "candidates-idSlug-matches",
    path: matchesL1j5R5I5l0Meta?.path ?? "matches",
    meta: matchesL1j5R5I5l0Meta || {},
    alias: matchesL1j5R5I5l0Meta?.alias || [],
    redirect: matchesL1j5R5I5l0Meta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/[idSlug]/matches.vue").then(m => m.default || m)
  },
  {
    name: openSelectionsxbTGNWrwYDMeta?.name ?? "candidates-idSlug-openSelections",
    path: openSelectionsxbTGNWrwYDMeta?.path ?? "openSelections",
    meta: openSelectionsxbTGNWrwYDMeta || {},
    alias: openSelectionsxbTGNWrwYDMeta?.alias || [],
    redirect: openSelectionsxbTGNWrwYDMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/[idSlug]/openSelections.vue").then(m => m.default || m)
  },
  {
    name: statisticsQJAVMubjnwMeta?.name ?? "candidates-idSlug-statistics",
    path: statisticsQJAVMubjnwMeta?.path ?? "statistics",
    meta: statisticsQJAVMubjnwMeta || {},
    alias: statisticsQJAVMubjnwMeta?.alias || [],
    redirect: statisticsQJAVMubjnwMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/[idSlug]/statistics.vue").then(m => m.default || m)
  }
],
    meta: _91idSlug_93TT5wEUhtIPMeta || {},
    alias: _91idSlug_93TT5wEUhtIPMeta?.alias || [],
    redirect: _91idSlug_93TT5wEUhtIPMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/[idSlug].vue").then(m => m.default || m)
  },
  {
    name: add_45new_45matchmSncNoDuuPMeta?.name ?? "candidates-add-new-match",
    path: add_45new_45matchmSncNoDuuPMeta?.path ?? "/candidates/add-new-match",
    meta: add_45new_45matchmSncNoDuuPMeta || {},
    alias: add_45new_45matchmSncNoDuuPMeta?.alias || [],
    redirect: add_45new_45matchmSncNoDuuPMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/add-new-match.vue").then(m => m.default || m)
  },
  {
    name: edit_45candidateVh7cSupZYFMeta?.name ?? "candidates-edit-candidate",
    path: edit_45candidateVh7cSupZYFMeta?.path ?? "/candidates/edit-candidate",
    meta: edit_45candidateVh7cSupZYFMeta || {},
    alias: edit_45candidateVh7cSupZYFMeta?.alias || [],
    redirect: edit_45candidateVh7cSupZYFMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/edit-candidate.vue").then(m => m.default || m)
  },
  {
    name: edit_45matchyanzUzJ8tzMeta?.name ?? "candidates-edit-match",
    path: edit_45matchyanzUzJ8tzMeta?.path ?? "/candidates/edit-match",
    meta: edit_45matchyanzUzJ8tzMeta || {},
    alias: edit_45matchyanzUzJ8tzMeta?.alias || [],
    redirect: edit_45matchyanzUzJ8tzMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/edit-match.vue").then(m => m.default || m)
  },
  {
    name: index8qoBs9RHF9Meta?.name ?? "candidates",
    path: index8qoBs9RHF9Meta?.path ?? "/candidates",
    meta: index8qoBs9RHF9Meta || {},
    alias: index8qoBs9RHF9Meta?.alias || [],
    redirect: index8qoBs9RHF9Meta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/candidates/index.vue").then(m => m.default || m)
  },
  {
    name: _91companyId_93WB74GTE9NSMeta?.name ?? "companies-companyId",
    path: _91companyId_93WB74GTE9NSMeta?.path ?? "/companies/:companyId()",
    children: [
  {
    name: applications3wpQj5Kw3dMeta?.name ?? "companies-companyId-applications",
    path: applications3wpQj5Kw3dMeta?.path ?? "applications",
    meta: applications3wpQj5Kw3dMeta || {},
    alias: applications3wpQj5Kw3dMeta?.alias || [],
    redirect: applications3wpQj5Kw3dMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/companies/[companyId]/applications.vue").then(m => m.default || m)
  },
  {
    name: detailsqWURrbsmbqMeta?.name ?? "companies-companyId-details",
    path: detailsqWURrbsmbqMeta?.path ?? "details",
    meta: detailsqWURrbsmbqMeta || {},
    alias: detailsqWURrbsmbqMeta?.alias || [],
    redirect: detailsqWURrbsmbqMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/companies/[companyId]/details.vue").then(m => m.default || m)
  },
  {
    name: historyObAKukkssRMeta?.name ?? "companies-companyId-history",
    path: historyObAKukkssRMeta?.path ?? "history",
    meta: historyObAKukkssRMeta || {},
    alias: historyObAKukkssRMeta?.alias || [],
    redirect: historyObAKukkssRMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/companies/[companyId]/history.vue").then(m => m.default || m)
  },
  {
    name: jobPositionsF73rqlxTLZMeta?.name ?? "companies-companyId-jobPositions",
    path: jobPositionsF73rqlxTLZMeta?.path ?? "jobPositions",
    meta: jobPositionsF73rqlxTLZMeta || {},
    alias: jobPositionsF73rqlxTLZMeta?.alias || [],
    redirect: jobPositionsF73rqlxTLZMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/companies/[companyId]/jobPositions.vue").then(m => m.default || m)
  },
  {
    name: matchesKl3Vwv0wUtMeta?.name ?? "companies-companyId-matches",
    path: matchesKl3Vwv0wUtMeta?.path ?? "matches",
    meta: matchesKl3Vwv0wUtMeta || {},
    alias: matchesKl3Vwv0wUtMeta?.alias || [],
    redirect: matchesKl3Vwv0wUtMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/companies/[companyId]/matches.vue").then(m => m.default || m)
  },
  {
    name: openSelectionspzAU4CgS2AMeta?.name ?? "companies-companyId-openSelections",
    path: openSelectionspzAU4CgS2AMeta?.path ?? "openSelections",
    meta: openSelectionspzAU4CgS2AMeta || {},
    alias: openSelectionspzAU4CgS2AMeta?.alias || [],
    redirect: openSelectionspzAU4CgS2AMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/companies/[companyId]/openSelections.vue").then(m => m.default || m)
  },
  {
    name: usersFJCP46mIfxMeta?.name ?? "companies-companyId-users",
    path: usersFJCP46mIfxMeta?.path ?? "users",
    meta: usersFJCP46mIfxMeta || {},
    alias: usersFJCP46mIfxMeta?.alias || [],
    redirect: usersFJCP46mIfxMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/companies/[companyId]/users.vue").then(m => m.default || m)
  }
],
    meta: _91companyId_93WB74GTE9NSMeta || {},
    alias: _91companyId_93WB74GTE9NSMeta?.alias || [],
    redirect: _91companyId_93WB74GTE9NSMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/companies/[companyId].vue").then(m => m.default || m)
  },
  {
    name: add_45new_45userFJMrVcWImtMeta?.name ?? "companies-add-new-user",
    path: add_45new_45userFJMrVcWImtMeta?.path ?? "/companies/add-new-user",
    meta: add_45new_45userFJMrVcWImtMeta || {},
    alias: add_45new_45userFJMrVcWImtMeta?.alias || [],
    redirect: add_45new_45userFJMrVcWImtMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/companies/add-new-user.vue").then(m => m.default || m)
  },
  {
    name: edit_45userHwlFxMsugDMeta?.name ?? "companies-edit-user",
    path: edit_45userHwlFxMsugDMeta?.path ?? "/companies/edit-user",
    meta: edit_45userHwlFxMsugDMeta || {},
    alias: edit_45userHwlFxMsugDMeta?.alias || [],
    redirect: edit_45userHwlFxMsugDMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/companies/edit-user.vue").then(m => m.default || m)
  },
  {
    name: indexXDuUlZTeyJMeta?.name ?? "companies",
    path: indexXDuUlZTeyJMeta?.path ?? "/companies",
    meta: indexXDuUlZTeyJMeta || {},
    alias: indexXDuUlZTeyJMeta?.alias || [],
    redirect: indexXDuUlZTeyJMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/companies/index.vue").then(m => m.default || m)
  },
  {
    name: index5i8gmPKmlIMeta?.name ?? "index",
    path: index5i8gmPKmlIMeta?.path ?? "/",
    meta: index5i8gmPKmlIMeta || {},
    alias: index5i8gmPKmlIMeta?.alias || [],
    redirect: index5i8gmPKmlIMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91jobId_939kNSMWaTmCMeta?.name ?? "job-positions-jobId",
    path: _91jobId_939kNSMWaTmCMeta?.path ?? "/job-positions/:jobId()",
    children: [
  {
    name: applicationsqPGJYXZEq1Meta?.name ?? "job-positions-jobId-applications",
    path: applicationsqPGJYXZEq1Meta?.path ?? "applications",
    meta: applicationsqPGJYXZEq1Meta || {},
    alias: applicationsqPGJYXZEq1Meta?.alias || [],
    redirect: applicationsqPGJYXZEq1Meta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/job-positions/[jobId]/applications.vue").then(m => m.default || m)
  },
  {
    name: detailsgnxIF9aZmCMeta?.name ?? "job-positions-jobId-details",
    path: detailsgnxIF9aZmCMeta?.path ?? "details",
    meta: detailsgnxIF9aZmCMeta || {},
    alias: detailsgnxIF9aZmCMeta?.alias || [],
    redirect: detailsgnxIF9aZmCMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/job-positions/[jobId]/details.vue").then(m => m.default || m)
  },
  {
    name: historybcIxhNeiu0Meta?.name ?? "job-positions-jobId-history",
    path: historybcIxhNeiu0Meta?.path ?? "history",
    meta: historybcIxhNeiu0Meta || {},
    alias: historybcIxhNeiu0Meta?.alias || [],
    redirect: historybcIxhNeiu0Meta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/job-positions/[jobId]/history.vue").then(m => m.default || m)
  },
  {
    name: matchesxYmEEGLlsaMeta?.name ?? "job-positions-jobId-matches",
    path: matchesxYmEEGLlsaMeta?.path ?? "matches",
    meta: matchesxYmEEGLlsaMeta || {},
    alias: matchesxYmEEGLlsaMeta?.alias || [],
    redirect: matchesxYmEEGLlsaMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/job-positions/[jobId]/matches.vue").then(m => m.default || m)
  },
  {
    name: openSelectionsq1gzsBmEnTMeta?.name ?? "job-positions-jobId-openSelections",
    path: openSelectionsq1gzsBmEnTMeta?.path ?? "openSelections",
    meta: openSelectionsq1gzsBmEnTMeta || {},
    alias: openSelectionsq1gzsBmEnTMeta?.alias || [],
    redirect: openSelectionsq1gzsBmEnTMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/job-positions/[jobId]/openSelections.vue").then(m => m.default || m)
  },
  {
    name: statisticsghne00eiMoMeta?.name ?? "job-positions-jobId-statistics",
    path: statisticsghne00eiMoMeta?.path ?? "statistics",
    meta: statisticsghne00eiMoMeta || {},
    alias: statisticsghne00eiMoMeta?.alias || [],
    redirect: statisticsghne00eiMoMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/job-positions/[jobId]/statistics.vue").then(m => m.default || m)
  }
],
    meta: _91jobId_939kNSMWaTmCMeta || {},
    alias: _91jobId_939kNSMWaTmCMeta?.alias || [],
    redirect: _91jobId_939kNSMWaTmCMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/job-positions/[jobId].vue").then(m => m.default || m)
  },
  {
    name: add_45new_45jobJRehqABxkRMeta?.name ?? "job-positions-add-new-job",
    path: add_45new_45jobJRehqABxkRMeta?.path ?? "/job-positions/add-new-job",
    meta: add_45new_45jobJRehqABxkRMeta || {},
    alias: add_45new_45jobJRehqABxkRMeta?.alias || [],
    redirect: add_45new_45jobJRehqABxkRMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/job-positions/add-new-job.vue").then(m => m.default || m)
  },
  {
    name: edit_45jobpoFKpWNxrgMeta?.name ?? "job-positions-edit-job",
    path: edit_45jobpoFKpWNxrgMeta?.path ?? "/job-positions/edit-job",
    meta: edit_45jobpoFKpWNxrgMeta || {},
    alias: edit_45jobpoFKpWNxrgMeta?.alias || [],
    redirect: edit_45jobpoFKpWNxrgMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/job-positions/edit-job.vue").then(m => m.default || m)
  },
  {
    name: index_322TURZEJnGVeMeta?.name ?? "job-positions-index 2",
    path: index_322TURZEJnGVeMeta?.path ?? "/job-positions/index%202",
    meta: index_322TURZEJnGVeMeta || {},
    alias: index_322TURZEJnGVeMeta?.alias || [],
    redirect: index_322TURZEJnGVeMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/job-positions/index 2.vue").then(m => m.default || m)
  },
  {
    name: indexmlpnnAIyeXMeta?.name ?? "job-positions",
    path: indexmlpnnAIyeXMeta?.path ?? "/job-positions",
    meta: indexmlpnnAIyeXMeta || {},
    alias: indexmlpnnAIyeXMeta?.alias || [],
    redirect: indexmlpnnAIyeXMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/job-positions/index.vue").then(m => m.default || m)
  },
  {
    name: add_45new_45matchWtHNqicENXMeta?.name ?? "matches-add-new-match",
    path: add_45new_45matchWtHNqicENXMeta?.path ?? "/matches/add-new-match",
    meta: add_45new_45matchWtHNqicENXMeta || {},
    alias: add_45new_45matchWtHNqicENXMeta?.alias || [],
    redirect: add_45new_45matchWtHNqicENXMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/matches/add-new-match.vue").then(m => m.default || m)
  },
  {
    name: applicationsxhqGp7jgPNMeta?.name ?? "matches-applications",
    path: applicationsxhqGp7jgPNMeta?.path ?? "/matches/applications",
    meta: applicationsxhqGp7jgPNMeta || {},
    alias: applicationsxhqGp7jgPNMeta?.alias || [],
    redirect: applicationsxhqGp7jgPNMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/matches/applications.vue").then(m => m.default || m)
  },
  {
    name: historyWKFXdGgRz1Meta?.name ?? "matches-history",
    path: historyWKFXdGgRz1Meta?.path ?? "/matches/history",
    meta: historyWKFXdGgRz1Meta || {},
    alias: historyWKFXdGgRz1Meta?.alias || [],
    redirect: historyWKFXdGgRz1Meta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/matches/history.vue").then(m => m.default || m)
  },
  {
    name: index5pIWalzVbqMeta?.name ?? "matches",
    path: index5pIWalzVbqMeta?.path ?? "/matches",
    meta: index5pIWalzVbqMeta || {},
    alias: index5pIWalzVbqMeta?.alias || [],
    redirect: index5pIWalzVbqMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/matches/index.vue").then(m => m.default || m)
  },
  {
    name: matchesytNMNP8VRhMeta?.name ?? "matches-matches",
    path: matchesytNMNP8VRhMeta?.path ?? "/matches/matches",
    meta: matchesytNMNP8VRhMeta || {},
    alias: matchesytNMNP8VRhMeta?.alias || [],
    redirect: matchesytNMNP8VRhMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/matches/matches.vue").then(m => m.default || m)
  },
  {
    name: openSelectionsL7H8tKl89VMeta?.name ?? "matches-openSelections",
    path: openSelectionsL7H8tKl89VMeta?.path ?? "/matches/openSelections",
    meta: openSelectionsL7H8tKl89VMeta || {},
    alias: openSelectionsL7H8tKl89VMeta?.alias || [],
    redirect: openSelectionsL7H8tKl89VMeta?.redirect || undefined,
    component: () => import("/Users/salvatoreesposito/Documents/@Codemotion/work-project/cm-talent-partner-bo/pages/matches/openSelections.vue").then(m => m.default || m)
  }
]